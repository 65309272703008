<template>
	<div :class="'mahjong-module zoom-level-' + zoomLevel">
		<div
			class="alert alert-success alert-dismissible fade show"
			role="alert"
			v-if="winner == playerId && gameEndNotification"
		>
			<strong>Congrats!</strong> You've won!
			<button
				type="button"
				class="btn-close"
				v-on:click="gameEndNotification = false"
			></button>
		</div>
		<div
			class="alert alert-danger alert-dismissible fade show"
			role="alert"
			v-if="winner != playerId && gameEndNotification"
		>
			<strong>R.I.P.</strong> Player {{ winner }} won.
			<button
				type="button"
				class="btn-close"
				v-on:click="gameEndNotification = false"
			></button>
		</div>

		<div class="game-board">
			<div
				v-for="discardIndex in [0, 1, 2, 3]"
				v-bind:key="discardIndex"
				v-bind:class="'discard discard' + discardIndex"
			>
				<Tile
					v-for="(tile, index) in discards[
						nextPlayerId(discardIndex)
					]"
					:key="tile"
					v-bind="{
						tile: tile,
						discarded: true,
						lastAction: isLastDiscard(
							nextPlayerId(discardIndex),
							index
						),
					}"
				></Tile>
			</div>
			<div class="remaining-tiles">
				{{ remainingTiles }} tiles
				<br />
				left
			</div>
			<div class="turn-indicator" v-if="currentPlayer > -1">
				<img
					class="turn-pointer"
					v-for="turnIndex in [(currentPlayer - playerId + 4) % 4]"
					v-bind:key="turnIndex"
					v-bind:id="'turn' + turnIndex"
					src="../assets/pointer.png"
				/>
			</div>
			<div class="actions" v-if="chiChoices">
				<div
					class="tiles"
					v-for="(indices, chiIndex) in chiChoices"
					:key="chiIndex"
				>
					<Tile
						v-for="index in indices"
						:key="index"
						v-on:click="chooseChi(chiIndex)"
						v-bind:tile="hands[playerId][index]"
						@mouseover="
							() => {
								highlights = indices;
							}
						"
						@mouseLeave="clearHighlights()"
					></Tile>
				</div>
			</div>

			<div class="actions" v-if="specialsPossibilities">
				<button
					class="btn btn-success"
					v-if="specialsPossibilities['chi']"
					v-on:click="chooseSpecial('chi')"
					@mouseover="setHighlights('chi')"
					@mouseLeave="clearHighlights()"
				>
					Chi
				</button>
				<button
					class="btn btn-info"
					v-if="specialsPossibilities['peng']"
					v-on:click="chooseSpecial('peng')"
					@mouseover="setHighlights('peng')"
					@mouseLeave="clearHighlights()"
				>
					Peng
				</button>
				<button
					class="btn btn-warning"
					v-if="specialsPossibilities['gang']"
					v-on:click="chooseSpecial('gang')"
					@mouseover="setHighlights('gang')"
					@mouseLeave="clearHighlights()"
				>
					Gang
				</button>
				<button
					class="btn btn-warning"
					v-if="specialsPossibilities['gang+']"
					v-on:click="chooseSpecial('gang+')"
					@mouseover="setHighlights('gang')"
					@mouseLeave="clearHighlights()"
				>
					Gang+
				</button>
				<button
					class="btn btn-danger"
					v-if="specialsPossibilities['hu']"
					v-on:click="chooseSpecial('hu')"
				>
					Hu
				</button>
				<button
					class="btn btn-secondary"
					v-on:click="chooseSpecial('pass')"
				>
					Pass
				</button>
			</div>
		</div>

		<div class="hand hand0">
			<div class="tiles">
				<Tile
					v-for="(tile, index) in hands[playerId]"
					:key="index"
					v-bind="{
						tile: tile,
						index: index,
						highlights: highlights,
						hoverable: true,
						lastAction:
							playerId == winner && winningTileIndex == index,
					}"
					v-on:click="playTile(tile, false)"
				></Tile>
			</div>
			<transition name="slide-fade">
				<Tile
					v-bind:newTile="true"
					v-show="newTile[playerId] != null"
					v-bind="{
						tile: newTile[playerId],
						hoverable: true,
					}"
					v-on:click="playTile(newTile[playerId], true)"
				></Tile>
			</transition>
			<div class="specials">
				<div
					class="tiles"
					v-for="(special, specialIndex) in specials[playerId]"
					:key="specialIndex"
				>
					<Tile
						v-for="(tile, index) in special"
						:key="index"
						v-bind="{
							tile: tile,
							lastAction:
								gameState ==
									/* STATE_DISCARD_AFTER_SPECIAL= */ 3 &&
								specialIndex == specials[playerId].length - 1 &&
								playerId == currentPlayer,
						}"
					></Tile>
				</div>
			</div>
		</div>

		<div
			v-bind:class="'hand hand' + handIndex"
			v-for="handIndex in [1, 2, 3]"
			v-bind:key="handIndex"
		>
			<div class="tiles">
				<Tile
					v-for="(tile, index) in hands[nextPlayerId(handIndex)]"
					:key="index"
					v-bind="{
						tile: tile,
						back:
							winner != nextPlayerId(handIndex) &&
							!openedHands[nextPlayerId(handIndex)],
						lastAction:
							nextPlayerId(handIndex) == winner &&
							winningTileIndex == index,
					}"
				></Tile>
			</div>
			<Tile
				v-bind:newTile="true"
				v-show="newTile[nextPlayerId(handIndex)] != null"
				v-bind:back="true"
			></Tile>
			<div class="specials">
				<div
					class="tiles"
					v-for="(special, specialIndex) in specials[
						nextPlayerId(handIndex)
					]"
					:key="specialIndex"
				>
					<Tile
						v-for="(tile, index) in special"
						:key="index"
						v-bind="{
							tile: tile,
							lastAction:
								gameState ==
									/* STATE_DISCARD_AFTER_SPECIAL= */ 3 &&
								specialIndex ==
									specials[nextPlayerId(handIndex)].length -
										1 &&
								nextPlayerId(handIndex) == currentPlayer,
						}"
					></Tile>
				</div>
			</div>
		</div>

		<div
			:class="'profile profile' + ((index - playerId + 4) % 4)"
			v-for="index in activeUsers()"
			v-bind:key="index"
		>
			<img
				class="border border-dark border-2"
				id="portrait"
				:src="getAvatarImage(avatars[index])"
			/>
			<h1>{{ usernames[index] }}</h1>
		</div>

		<button
			id="settings-toggle"
			class="btn btn-primary"
			data-bs-toggle="modal"
			data-bs-target="#settings"
		>
			<i class="fas fa-bars fa-lg"></i>
		</button>
		<div class="modal fade" id="settings" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Game Settings</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<button
							class="btn btn-primary mb-3"
							v-on:click="newGame()"
						>
							New Game
						</button>
						<div class="input-group mb-3">
							<label class="input-group-text" for="maxPlayers"
								>Max Players</label
							>
							<select
								class="form-select"
								id="maxPlayers"
								v-model="playersCount"
							>
								<option>2</option>
								<option>3</option>
								<option>4</option>
							</select>
						</div>
						<ul class="list-group">
							<li class="list-group-item">
								Room Id: {{ $route.params.roomId }}
							</li>
							<li class="list-group-item">
								Name: {{ usernames[0] }}
							</li>
							<li class="list-group-item">
								Player Id: {{ playerId }}
							</li>
							<li class="list-group-item">
								Current Player: {{ currentPlayer }}
							</li>
							<li class="list-group-item">
								State: {{ gameStateAsString(gameState) }}
							</li>
						</ul>
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-bs-dismiss="modal"
						>
							Close
						</button>
						<button type="button" class="btn btn-primary">
							Save changes
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="debug">
			<div class="input-group mb-3">
				<label class="input-group-text" for="maxPlayers"
					>Max Players</label
				>
				<select
					class="form-select"
					id="maxPlayers"
					v-model="playersCount"
				>
					<option>2</option>
					<option>3</option>
					<option>4</option>
				</select>
			</div>
			<button class="btn btn-primary mb-3" v-on:click="newGame()">
				New Game
			</button>
			<button
				v-if="
					winner > -1 && winner != playerId && !openedHands[playerId]
				"
				class="btn btn-primary mb-3"
				v-on:click="openHand()"
			>
				Open Hand
			</button>
		</div>
	</div>
</template>

<script>
import io from "socket.io-client";
import Tile from "./Tile.vue";
import { PROD } from "../App.vue";

const playSound = new Audio(require("../assets/audio/chess_piece.mp3"));
const sevenDaysLater = new Audio(
	require("../assets/audio/seven_days_later.mp3")
);

let timeout = null;

export default {
	name: "MahjongGame",
	components: {
		Tile,
	},
	data() {
		return {
			gameState: -1,
			socket: null,
			playerId: 0,
			usernames: ["", "", "", ""],
			avatars: [-1, -1, -1, -1],
			currentPlayer: 0,
			hands: [[], [], [], []],
			discards: [[], [], [], []],
			newTile: [null, null, null, null],
			specials: [[], [], [], []],
			winner: -1,
			winningTileIndex: -1,
			gameEndNotification: false,
			specialsPossibilities: null,
			chiChoices: null,
			playedOnce: true,
			remainingTiles: 0,
			highlights: null,
			playersCount: 2,
			lastDiscardingPlayer: -1,
			openedHands: [false, false, false, false],
			PROD: true,
		};
	},
	created() {
		this.usernames[0] = localStorage.username;
		this.PROD = PROD;

		this.socket = io(
			PROD ? "https://www.lemonz.io" : "http://localhost:8080/",
			{ path: "/api" }
		);

		this.socket.on("getConnectedInfo", () => {
			this.socket.emit("connectedInfo", {
				roomId: this.$route.params.roomId,
				username: this.usernames[0],
			});
		});
	},
	mounted() {
		this.socket.on("update", (updateInfo) => {
			if (updateInfo.gameState) {
				this.gameState = updateInfo.gameState;
			}
			if (updateInfo.playedTile) {
				playSound.play();
			}
			if (updateInfo.usernames) {
				this.usernames = updateInfo.usernames;
			}
			if (updateInfo.avatars) {
				this.avatars = updateInfo.avatars;
			}
		});

		this.socket.on("gameState", this.updateGameState);

		this.socket.on("specials", (specials) => {
			console.log("specials: " + specials);
			this.specialsPossibilities = specials;
			this.playedOnce = false;
		});

		this.socket.on("openHand", (playerId) => {
			this.openedHands[playerId] = true;
		});
	},
	unmounted() {
		this.socket.disconnect();
	},
	methods: {
		playTile(tile, playedNewTile) {
			console.log("playTile: " + this.tileToString(tile));
			if (this.winner > -1) {
				console.log("Game is finished");
				return;
			}
			if (this.chiChoices || this.specialsPossibilities) {
				console.log("Must finish other action!");
				return;
			}
			if (this.playerId != this.currentPlayer) {
				console.log("Not your turn!");
				return;
			}
			if (this.playedOnce) {
				console.log("Already played this turn");
				return;
			}
			this.playedOnce = true;
			this.socket.emit("playTile", {
				tile: tile,
				playedNewTile: playedNewTile,
			});
		},
		tileToString(tile) {
			return (
				tile.mType + (tile.mValue === 0 ? "" : tile.mValue.toString())
			);
		},
		newGame() {
			this.setPlayerCount();
			this.socket.emit("newGame");
			this.specialsPossibilities = null;
			this.chiChoices = null;
			this.openedHands = [false, false, false, false];
		},
		getImageSrc(tile) {
			return require("../assets/tiles/" +
				this.tileToString(tile) +
				".png");
		},
		chooseSpecial(special) {
			console.log("chooseSpecial: " + special);
			this.clearHighlights();
			if (special == "pass") {
				this.playedOnce = true;
			}
			if (special == "chi") {
				let choices = this.specialsPossibilities["chi"];
				if (choices.length > 1) {
					this.chiChoices = choices;
					this.specialsPossibilities = null;
					return;
				}
			}
			let indices = this.specialsPossibilities[special];
			if (special == "chi") {
				indices = indices[0];
			}
			this.socket.emit("chooseSpecial", {
				type: special,
				indices: indices,
			});
			this.specialsPossibilities = null;
		},
		chooseChi(i_index) {
			console.log("chooseChi: " + this.chiChoices[i_index]);
			this.socket.emit("chooseSpecial", {
				type: "chi",
				indices: this.chiChoices[i_index],
			});
			this.chiChoices = null;
			this.clearHighlights();
		},
		isYourTurn() {
			if (this.currentPlayer == this.playerId) {
				return true;
			}

			if (this.specialsPossibilities || this.chiChoices) {
				return true;
			}

			return false;
		},
		setHighlights(special) {
			let res = this.specialsPossibilities[special];
			if (special == "chi") {
				res = [];
				this.specialsPossibilities["chi"].forEach((s) => {
					res.push(s[0]);
					res.push(s[1]);
				});
			}
			this.highlights = res;
		},
		clearHighlights() {
			this.highlights = null;
		},
		nextPlayerId(inc) {
			return (this.playerId + inc) % 4;
		},
		setPlayerCount() {
			this.socket.emit("setPlayersCount", this.playersCount);
		},
		isLastDiscard(playerId, index) {
			return (
				index == this.discards[playerId].length - 1 &&
				playerId == this.lastDiscardingPlayer &&
				this.gameState != /* STATE_DISCARD_AFTER_SPECIAL= */ 3 &&
				this.gameState != /* STATE_FINISHED= */ 4
			);
		},
		openHand() {
			this.openedHands[this.playerId] = true;
			this.socket.emit("openHand", this.playerId);
		},
		gameStateAsString(gameState) {
			switch (gameState) {
				case 1:
					return "STATE_DISCARD";
				case 2:
					return "STATE_WAITING_SPECIAL";
				case 3:
					return "STATE_DISCARD_AFTER_SPECIAL";
				case 4:
					return "STATE_FINISHED";
			}
		},
		updateGameState(gameState) {
			this.gameState = gameState.gameState;
			this.playerId = gameState.playerId;
			this.currentPlayer = gameState.currentPlayer;
			this.hands = gameState.hands;
			this.specials = gameState.specials;
			this.discards = gameState.discards;
			this.newTile = gameState.newTile;
			if (this.newTile[this.playerId]) {
				this.playedOnce = false;
			}
			this.winner = gameState.winner;
			this.winningTileIndex = gameState.winningTileIndex;
			this.gameEndNotification = this.winner > -1;
			this.remainingTiles = gameState.remainingTiles;
			this.playersCount = gameState.playersCount;
			this.lastDiscardingPlayer = gameState.lastDiscardingPlayer;
			this.openedHands = [false, false, false, false];
		},
		activeUsers() {
			let actives = [];
			for (let i = 0; i < 4; i++) {
				if (this.usernames[i].length > 0) {
					actives.push(i);
				}
			}
			return actives;
		},
		getAvatarImage(id) {
			if (id < 0) return null;
			return require("../assets/avatars/avatar" + id + ".png");
		},
	},
};
</script>

<style scoped>
.mahjong-module {
	width: 95vmin;
	margin-top: 2.5vmin;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
}

.mahjong-module::after {
	content: "";
	padding-bottom: 100%;
}

.game-board {
	width: 80%;
	height: 80%;
	background-color: olivedrab;
	position: absolute;
	left: 10%;
	top: 10%;
}

.discard {
	width: 32%;
	height: 32%;
	padding: 2px;
	position: absolute;
	margin: 10px;
}

.discard0 {
	left: calc(34% - 10px);
	bottom: 0px;
}

.discard2 {
	left: calc(34% - 10px);
	top: 0px;
}

.discard1 {
	right: 0px;
	top: calc(34% - 10px);
}

.discard3 {
	left: 0px;
	top: calc(34% - 10px);
}

.actions {
	position: absolute;
	width: 100%;
	bottom: 3vmin;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.actions.div {
	margin-right: 5px;
	margin-left: 5px;
}

.remaining-tiles {
	position: absolute;
	width: 80px;
	height: 40px;
	bottom: calc(50% - 20px);
	left: calc(50% - 40px);
	text-align: center;
	color: whitesmoke;
	font-weight: 900;
	font-size: 20px;
}

.turn-indicator {
	position: absolute;
	width: 80px;
	height: 80px;
	bottom: calc(50% - 40px);
	left: calc(50% - 40px);
	text-align: center;
	color: whitesmoke;
	font-weight: 900;
	font-size: 20px;
}

.turn-pointer {
	width: 30px;
	height: 30px;
	position: absolute;
}

#turn0 {
	bottom: -30px;
	left: 25px;
}

#turn1 {
	right: -30px;
	top: 25px;
	transform: rotate(270deg);
}

#turn2 {
	top: -30px;
	left: 25px;
	transform: rotate(180deg);
}

#turn3 {
	left: -30px;
	top: 25px;
	transform: rotate(90deg);
}

.hand {
	width: 80%;
	height: 10%;
	padding-left: 10px;
	padding-right: 10px;
	background-color: darkslateblue;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: absolute;
}

.hand0 {
	bottom: 0px;
	left: 10%;
}

.hand2 {
	top: 0px;
	left: 10%;
}
.hand3 {
	left: -35%;
	transform: rotate(90deg);
	transform-origin: center;
}
.hand1 {
	right: -35%;
	transform: rotate(270deg);
	transform-origin: center;
}

.tiles {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.specials {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.alert {
	z-index: 1000;
	position: absolute;
	top: -10vmin;
}

.slide-fade-enter-active {
	transition: all 0.5s ease;
	top: -30px;
	opacity: 0;
}

.slide-fade-enter-to {
	transform: translateY(30px);
	opacity: 1;
}

.profile {
	position: absolute;
	width: 10vmin;
	height: 10vmin;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

.profile0 {
	bottom: 10vmin;
	right: 15vmin;
}

.profile2 {
	top: 10vmin;
	left: 15vmin;
	transform: rotate(180deg);
}

.profile1 {
	right: 10vmin;
	top: 15vmin;
	transform: rotate(-90deg);
}

.profile3 {
	left: 10vmin;
	bottom: 15vmin;
	transform: rotate(90deg);
}

.profile img {
	width: 7vmin;
	height: 7vmin;
	object-fit: cover;
}

.profile h1 {
	color: white;
	font-size: 2vmin;
	margin: 0px;
}

.debug {
	width: 200px;
	position: absolute;
	right: -27vmin;
}

#settings-toggle {
	position: absolute;
	top: 0;
	right: -10vmin;
}
</style>
