<template>
    <div>
        <div
            class="section container-fluid d-flex flex-column align-items-center"
        >
            <img
                class="border border-warning rounded-circle border-5"
                id="portrait"
                src="../assets/lemonz.jpeg"
            />
            <router-link to="/">
                <h1 class="title yellow text-center">lemonz.io</h1>
            </router-link>
            <div class="links">
                <a href="https://github.com/AdamHe17"
                    ><i class="fa fa-github fa-3x"></i
                ></a>
                <a href="mailto:shuaihe2018@u.northwestern.edu"
                    ><i class="fa fa-envelope-o fa-3x"></i
                ></a>
                <a href="https://www.linkedin.com/in/shuaihe"
                    ><i class="fa fa-linkedin-square fa-3x"></i
                ></a>
            </div>
        </div>
        <div class="section container-fluid yellow-bg">
            <h1 class="black">Projects</h1>
            <br />
            <div class="row">
                <div
                    class="card-holder col-md-6 col-lg-4 col-xl-3"
                    v-for="(project, index) in projects"
                    v-bind:key="index"
                >
                    <div class="card text-white bg-dark mb-3">
                        <img
                            :src="require('../assets/' + project.img)"
                            class="card-img-top"
                        />
                        <div class="card-body">
                            <h5 class="card-title">{{ project.name }}</h5>
                            <p class="card-text">
                                {{ project.description }}
                            </p>
                            <router-link
                                v-if="project.action.path"
                                v-bind:to="project.action.path"
                                class="btn btn-outline-light"
                            >
                                {{ project.action.name }}
                            </router-link>
                            <a
                                v-if="project.action.url"
                                :href="project.action.url"
                                class="btn btn-outline-light"
                            >
                                {{ project.action.name }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { projects } from "../assets/projects";

export default {
    name: "Home",
    data() {
        return {
            projects: projects,
        };
    },
};
</script>

<style>
a {
    text-decoration: none;
}

h1 {
    font-weight: bold;
}

.section {
    padding: 20px;
}

.section > * {
    margin: 10px;
}

.links {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.links * {
    color: whitesmoke;
}

.links *:hover {
    color: grey;
}

#portrait {
    max-width: 250px;
    background-color: whitesmoke;
}

.yellow-bg {
    background-color: whitesmoke;
}

.yellow {
    color: #ffdf6c;
}

.black {
    color: #212121;
}

.title {
    font-size: 48px;
    text-decoration: underline;
    text-decoration-color: whitesmoke;
}

.card {
    max-width: 80%;
}

.card-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
