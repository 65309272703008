<template>
	<router-view v-slot="{ Component }">
		<transition name="fade" mode="out-in">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
export const PROD = true;

export default {
	name: "app",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
body {
	background-color: #212121;
	font-family: "Roboto", sans-serif;
}

#app {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s ease;
	opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
