<template>
	<div
		v-bind:class="[
			'tile-container',
			isHighlighted() ? 'raised' : '',
			hoverable ? 'hoverable' : '',
			discarded ? 'discarded' : '',
		]"
	>
		<div v-if="!back" v-bind:class="['tile', newTile ? 'new-tile' : '']">
			<div
				v-if="highlights != null && !isHighlighted()"
				class="dim"
			></div>
			<img src="../assets/tiles/front.png" />
			<img v-if="tile != null" :src="getImageSrc(tile)" />
			<p class="blur" v-if="tile != null">{{ tile.mNote }}</p>
			<img
				id="lastAction"
				v-if="lastAction"
				src="../assets/pointer.png"
			/>
		</div>
		<div v-else-if="back" :class="['tile', tileClass]">
			<img src="../assets/tiles/back.png" />
		</div>
	</div>
</template>

<script>
export default {
	name: "Tile",
	props: {
		tile: Object,
		index: Number,
		tileClass: String,
		highlights: Array,
		back: Boolean,
		hoverable: Boolean,
		lastAction: Boolean,
		winningTile: Boolean,
		discarded: Boolean,
		newTile: Boolean,
	},
	created() {},
	mounted() {},
	methods: {
		tileToString(tile) {
			return (
				tile.mType + (tile.mValue === 0 ? "" : tile.mValue.toString())
			);
		},
		getImageSrc(tile) {
			return require("../assets/tiles/" +
				this.tileToString(tile) +
				".png");
		},
		isHighlighted() {
			if (this.highlights == null) {
				return false;
			}
			return this.highlights.indexOf(this.index) > -1;
		},
	},
};
</script>

<style>
.tile-container {
	width: 4.5vmin;
	height: 6vmin;
	margin: 1px;
	display: inline-block;
	position: relative;
	justify-content: center;
	align-items: center;
}

.tile {
	width: 100%;
	height: 100%;
	margin: 0;
	transition-duration: 500ms;
	position: relative;
}

.tile img {
	width: 100%;
	height: 100%;
	position: absolute;
}

.tile img:nth-of-type(2) {
	width: 75%;
	height: 75%;
	top: 15%;
	left: 12.5%;
}

.new-tile {
	border: 2px solid red;
	border-radius: 5px;
}

.discarded {
	min-width: 0;
	min-height: 0;
	width: 12.6%;
	height: 16.8%;
	font-size: 1vmin;
}

.blur {
	position: absolute;
	right: 1.5px;
	margin: 0;
	color: red;
	font-weight: 600;
	font-size: 70%;
}

.raised,
.hoverable:hover .tile {
	transform: translateY(-25px);
	transition-duration: 500ms;
	border: 0px solid black;
	margin-bottom: 25px;
}

.hoverable:hover .new-tile {
	border: 2px solid red;
	border-radius: 5px;
}

.dim {
	width: 100%;
	height: 100%;
	z-index: 100;
	background-color: rgba(20, 20, 20, 0.6);
	border-radius: 5px;
	position: absolute;
}

#lastAction {
	position: absolute;
	top: -20px;
	width: 15px;
	height: 18px;
	left: calc(50% - 7.5px);

	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-name: bounce;
	animation-timing-function: ease-in-out;
}

@keyframes bounce {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY(0);
	}
}
</style>
