<template>
    <div
        class="section container-sm d-flex flex-column align-items-center yellow-bg"
    >
        <h1>Simple Mahjong</h1>
        <form class="needs-validation">
            <div class="mb-3">
                <label for="username-input" class="form-label">Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="username-input"
                    v-model="username"
                />
                <p class="text-danger" v-if="usernameValid == 1">
                    username can't be empty
                </p>
                <p class="text-danger" v-if="usernameValid == 2">
                    username must be at most 12 chars
                </p>
                <div class="invalid-feedback">Please choose a username.</div>
            </div>
            <div class="mb-3">
                <label for="room-id-input" class="form-label">Room Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="room-id-input"
                    v-model="roomId"
                />
                <p class="text-danger" v-if="roomIdValid == 1">
                    room id can't be empty
                </p>
                <p class="text-danger" v-if="roomIdValid == 2">
                    room id must be at most 12 chars
                </p>
            </div>
            <button
                class="btn btn-success"
                v-on:click="createGame()"
                type="button"
            >
                Submit
            </button>
        </form>
    </div>
</template>

<script>
export default {
    name: "MahjongLogin",
    props: {
        falseRoomId: String,
    },
    data() {
        return {
            username: "",
            roomId: "",
            usernameValid: 0,
            roomIdValid: 0,
        };
    },
    mounted() {
        console.log(localStorage);
        if (localStorage.username) {
            this.username = localStorage.username;
        }
        if (localStorage.roomId) {
            this.roomId = localStorage.roomId;
        }
    },
    methods: {
        validateInfo() {
            this.usernameValid = 0;
            this.roomIdValid = 0;

            if (this.username.length <= 0) {
                this.usernameValid = 1;
            } else if (this.username.length > 12) {
                this.usernameValid = 2;
            }

            if (this.roomId.length <= 0) {
                this.roomIdValid = 1;
            } else if (this.roomId.length > 12) {
                this.roomIdValid = 2;
            }

            return this.usernameValid + this.roomIdValid == 0;
        },
        createGame() {
            if (!this.validateInfo()) return;
            localStorage.username = this.username;
            localStorage.roomId = this.roomId;
            this.$router.push({
                name: "mahjongGame",
                params: { roomId: this.roomId },
            });
        },
    },
};
</script>

<style>
a {
    text-decoration: none;
}

h1 {
    font-weight: bold;
}

.background {
    height: 100vh;
    width: 100vh;
    position: absolute;
}

.section {
    margin-top: 10vh;
}

.links {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.links * {
    color: whitesmoke;
}

.links *:hover {
    color: grey;
}

#portrait {
    max-width: 250px;
    background-color: whitesmoke;
}

.yellow-bg {
    background-color: whitesmoke;
}

.yellow {
    color: #ffdf6c;
}

.black {
    color: #212121;
}

.title {
    font-size: 48px;
    text-decoration: underline;
    text-decoration-color: whitesmoke;
}

.col-sm-6 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
