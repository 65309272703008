export const projects = [
    {
        name: "Project Mahjong",
        img: "mahjong.jpeg",
        description: "Simple multiplayer mahjong game with basic rules",
        action: {
            name: "Play Mahjong",
            path: "/mahjong",
        },
    },
    {
        name: "Razy Rice (Wildhacks)",
        img: "razy_rice.png",
        description: "IoT remote controlled smart rice cooker",
        action: {
            name: "See on Github",
            url: "https://github.com/AdamHe17/razy-rice",
        },
    },
];
