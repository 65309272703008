import { createRouter, createWebHistory } from "vue-router";

import Home from "./components/Home";
import MahjongGame from "./components/MahjongGame";
import MahjongLogin from "./components/MahjongLogin";

const routes = [
    { path: "/", name: "home", component: Home },
    { path: "/mahjong", name: "mahjongLogin", component: MahjongLogin },
    {
        path: "/mahjong/:roomId",
        name: "mahjongGame",
        component: MahjongGame,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/:pathMatch(.*)",
        meta: {
            redirectHome: true,
        },
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.redirectHome)) {
        next({ name: "home" });
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!localStorage.username) {
            next({ name: "mahjongLogin" });
        } else {
            if (to.params.roomId != localStorage.roomId) {
                console.log("intercepted");
                next({
                    name: "mahjongLogin",
                    props: { falseRoomId: to.params.roomId },
                });
            } else {
                next(); // go to wherever I'm going
            }
        }
    } else {
        next(); // does not require auth, make sure to always call next()!
    }
});
